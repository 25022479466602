<template>
  <div class="event-lists" v-if="loaded">
    <div class="events-list">
      <div class="event-card" v-for="event in unFinishedEvents" :key="event.id">
        <h4>{{ event.name }}</h4>
        <p class="location" v-if="event.city">
          {{ event.city.name }}, {{ event.city.country }}
        </p>
        <p class="date">{{ eventStartDate(event.startDate) }}</p>
        <div class="tags">
          <TjingToolTip text="PDGA Tier" v-if="event.pdgaType">
            <span class="card-label ocean">{{ event.pdgaType.name }}</span>
          </TjingToolTip>

          <TjingToolTip
            text="From your location"
            v-if="distanceInKm(usersPosition, event.city)"
          >
            <span class="card-label sunrise"
              >{{ distanceInKm(usersPosition, event.city) }} km</span
            ></TjingToolTip
          >
          <span
            class="status-label grass"
            :class="{
              right: distanceInKm(usersPosition, event.city) || event.pdgaType,
            }"
            v-if="eventStarted(event.startDate)"
          >
            In progress
          </span>
        </div>

        <template v-if="!eventStarted(event.startDate)">
          <div class="status" v-if="showRegOpeningDate(event)">
            <TjingToolTip text="Registration status">
              <div>
                <RegistrationOpen color="sunrise" />
                <p>
                  Registration open
                  {{ regOpenDateFormatter(event.registrationOpenAt) }}
                </p>
              </div>
            </TjingToolTip>
          </div>

          <div class="status" v-else>
            <div class="registration" v-if="event.isRegistrationOpen">
              <TjingToolTip text="Registration open">
                <RegistrationOpen />
                <p>Open</p>
              </TjingToolTip>
            </div>
            <div class="registration" v-else>
              <TjingToolTip text="Registration closed">
                <Off />
                <p>Closed</p>
              </TjingToolTip>
            </div>
            <div class="confirmed">
              <TjingToolTip text="Confirmed">
                <ConfirmedPlayers />
                <p>{{ event.playersCount }}</p>
              </TjingToolTip>
            </div>
            <div class="waiting">
              <TjingToolTip text="Waiting">
                <WaitingList />
                <p>{{ eventWaitingList(event) }}</p>
              </TjingToolTip>
            </div>
          </div>
        </template>
        <router-link
          :to="{
            name: 'public-event-results',
            params: { eventId: event.id },
          }"
        >
          <button class="view-event">View event</button>
        </router-link>
      </div>
    </div>
    <div class="events-list" v-if="finishedEvents.length > 0">
      <p class="finished-header" v-if="tourOngoing">Finished events</p>
      <div class="event-card" v-for="event in finishedEvents" :key="event.id">
        <h4>{{ event.name }}</h4>
        <p class="location" v-if="event.city">
          {{ event.city.name }}, {{ event.city.country }}
        </p>
        <p class="date">{{ eventStartDate(event.startDate) }}</p>
        <div class="tags">
          <TjingToolTip text="PDGA Tier" v-if="event.pdgaType">
            <span class="card-label ocean">{{ event.pdgaType.name }}</span>
          </TjingToolTip>
          <TjingToolTip
            text="From your location"
            v-if="distanceInKm(usersPosition, event.city)"
          >
            <span class="card-label sunrise"
              >{{ distanceInKm(usersPosition, event.city) }} km</span
            >
          </TjingToolTip>
          <span
            class="status-label strom"
            :class="{
              right: distanceInKm(usersPosition, event.city) || event.pdgaType,
            }"
          >
            Finished
          </span>
        </div>
        <router-link
          :to="{
            name: 'public-event-results',
            params: { eventId: event.id },
          }"
        >
          <button class="view-event">View event</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import locationMixin from "@/mixins/locationMixin.js";
import moment from "moment";
import WaitingList from "@/assets/icons/WaitingList";
import ConfirmedPlayers from "@/assets/icons/ConfirmedPlayers";
import RegistrationOpen from "@/assets/icons/RegistrationOpen";
import Off from "@/assets/icons/Off";

export default {
  name: "PublicTourEventsList",
  components: {
    Off,
    WaitingList,
    ConfirmedPlayers,
    RegistrationOpen,
  },
  mixins: [locationMixin],
  data() {
    return {
      loaded: false,
      eventData: null,
      usersPosition: null,
    };
  },
  computed: {
    tourOngoing() {
      return (
        this.eventData.events.filter((event) => event.status !== "COMPLETED")
          .length > 0
      );
    },
    unFinishedEvents() {
      const startOfYesterday = moment().subtract(1, "days").startOf("day");

      return this.eventData.events.filter(
        (event) =>
          event.status !== "COMPLETED" &&
          moment(event.endDate).isAfter(startOfYesterday)
      );
    },
    finishedEvents() {
      const startOfYesterday = moment().subtract(1, "days").startOf("day");

      return this.eventData.events.filter(
        (event) =>
          event.status === "COMPLETED" ||
          moment(event.endDate).isBefore(startOfYesterday)
      );
    },
  },
  methods: {
    eventWaitingList(event) {
      let eventWaitingListCount = event.registrations.filter(
        (registration) => registration.status == "PENDING"
      ).length;

      return eventWaitingListCount;
    },
    getDistanceInKM(lat1, lon1, lat2, lon2) {
      function deg2rad(deg) {
        return deg * (Math.PI / 180);
      }
      var R = 6371; // Radius of the earth in km
      var dLat = deg2rad(lat2 - lat1); // deg2rad below
      var dLon = deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
          Math.cos(deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    },
    distanceInKm(userCoords, eventCoords) {
      let distance = 0;
      if (userCoords && eventCoords) {
        distance = this.getDistanceInKM(
          eventCoords.geolocation.lat,
          eventCoords.geolocation.lng,
          userCoords.lat,
          userCoords.lng
        );
      } else {
        distance = null;
      }
      return distance == null ? null : Math.round(distance * 10) / 10;
    },
    showRegOpeningDate(event) {
      if (event.isRegistrationOpen) return false;
      if (moment(event.registrationOpenAt).isAfter()) return true;
      return false;
    },
    regOpenDateFormatter(date) {
      return moment(date).format("D MMM [at] HH:mm");
    },
    eventStartDate(date) {
      return moment(date).format("dddd D MMMM [at] HH:mm");
    },
    eventStarted(date) {
      return moment().isAfter(date);
    },
    async loadData() {
      const eventData = await this.$axios({
        data: {
          query: `
          {
            tour(tourId: "${this.$router.currentRoute.params.tourId}") {
              id
              events{
                id
                name
                registrationsCount
                playersCount
                startDate
                endDate
                isRegistrationOpen
                registrationOpenAt
                status
                registrations{
                  id
                  status
                }
                city{
                  id
                  name
                  country
                  geolocation{
                    lat
                    lng
                  }
                }
                pdgaType{
                  name
                  type
                }
              }
            }
          }
          `,
        },
      });
      this.eventData = eventData.data.data.tour;
      this.loaded = true;
    },
  },
  async beforeMount() {
    this.loadData();
    try {
      this.usersPosition = await this.getLocation();
    } catch (err) {
      if (err.code == 1) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Location services disabled",
          type: "error",
        });
      }
    }
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.events-list {
  padding: 0 12px;

  .finished-header {
    margin-top: 32px;
    width: 100%;
    margin-bottom: 16px;
    font-size: 16px;
  }
}
.event-card {
  border-radius: 10px;
  padding: 18px 20px 22px 20px;
  border: 1px solid $sleet;
  width: 100%;
  margin-bottom: 24px;
  h4 {
    margin: 0;
    font-size: 20px;
  }
  p {
    font-size: 14px;
    margin: 0;

    &.location {
      margin-top: 6px;
    }
    &.date {
      @include Gilroy-Bold;
      margin-top: 8px;
    }
  }

  .tags {
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > div {
      margin-right: 10px;
    }

    .status-label {
      &.right {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
  .status {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div {
      display: flex;
      margin-right: 8px;
      align-items: center;
    }
    > div {
      margin-right: 8px;
    }
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .finished-tag {
      background: $cloud;
      padding: 3px 10px 2px 10px;
      border-radius: 15px;
      p {
        font-size: 10px;
        text-transform: uppercase;
        @include Gilroy-Bold;
        color: $blizzard;
        letter-spacing: 1px;
      }
    }
  }
  .view-event {
    margin-top: 24px;
    width: 100%;
    height: 48px;
    cursor: pointer;
    transition: all ease 0.3s;
    background: $cloud;
    border-radius: 6px;
    font-size: 16px;
    border: none;
    @include Gilroy-Bold;

    &:hover {
      background: $fog;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .events-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .finished-header {
      width: 100%;
      margin-bottom: 24px;
      font-size: 18px;
    }
  }
  .event-card {
    width: 48%;
    padding: 20px 22px 28px 22px;
    h4 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
}

@media (min-width: 1200px) {
  .events-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0;

    .finished-header {
      width: 100%;
      margin-bottom: 24px;
      font-size: 18px;
    }
  }
  .event-card {
    width: 30.3%;
    margin: 0 3% 32px 0;
    padding: 20px 22px 28px 22px;
    h4 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }
}
</style>
