var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"event-lists"},[_c('div',{staticClass:"events-list"},_vm._l((_vm.unFinishedEvents),function(event){return _c('div',{key:event.id,staticClass:"event-card"},[_c('h4',[_vm._v(_vm._s(event.name))]),(event.city)?_c('p',{staticClass:"location"},[_vm._v(" "+_vm._s(event.city.name)+", "+_vm._s(event.city.country)+" ")]):_vm._e(),_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.eventStartDate(event.startDate)))]),_c('div',{staticClass:"tags"},[(event.pdgaType)?_c('TjingToolTip',{attrs:{"text":"PDGA Tier"}},[_c('span',{staticClass:"card-label ocean"},[_vm._v(_vm._s(event.pdgaType.name))])]):_vm._e(),(_vm.distanceInKm(_vm.usersPosition, event.city))?_c('TjingToolTip',{attrs:{"text":"From your location"}},[_c('span',{staticClass:"card-label sunrise"},[_vm._v(_vm._s(_vm.distanceInKm(_vm.usersPosition, event.city))+" km")])]):_vm._e(),(_vm.eventStarted(event.startDate))?_c('span',{staticClass:"status-label grass",class:{
            right: _vm.distanceInKm(_vm.usersPosition, event.city) || event.pdgaType,
          }},[_vm._v(" In progress ")]):_vm._e()],1),(!_vm.eventStarted(event.startDate))?[(_vm.showRegOpeningDate(event))?_c('div',{staticClass:"status"},[_c('TjingToolTip',{attrs:{"text":"Registration status"}},[_c('div',[_c('RegistrationOpen',{attrs:{"color":"sunrise"}}),_c('p',[_vm._v(" Registration open "+_vm._s(_vm.regOpenDateFormatter(event.registrationOpenAt))+" ")])],1)])],1):_c('div',{staticClass:"status"},[(event.isRegistrationOpen)?_c('div',{staticClass:"registration"},[_c('TjingToolTip',{attrs:{"text":"Registration open"}},[_c('RegistrationOpen'),_c('p',[_vm._v("Open")])],1)],1):_c('div',{staticClass:"registration"},[_c('TjingToolTip',{attrs:{"text":"Registration closed"}},[_c('Off'),_c('p',[_vm._v("Closed")])],1)],1),_c('div',{staticClass:"confirmed"},[_c('TjingToolTip',{attrs:{"text":"Confirmed"}},[_c('ConfirmedPlayers'),_c('p',[_vm._v(_vm._s(event.playersCount))])],1)],1),_c('div',{staticClass:"waiting"},[_c('TjingToolTip',{attrs:{"text":"Waiting"}},[_c('WaitingList'),_c('p',[_vm._v(_vm._s(_vm.eventWaitingList(event)))])],1)],1)])]:_vm._e(),_c('router-link',{attrs:{"to":{
          name: 'public-event-results',
          params: { eventId: event.id },
        }}},[_c('button',{staticClass:"view-event"},[_vm._v("View event")])])],2)}),0),(_vm.finishedEvents.length > 0)?_c('div',{staticClass:"events-list"},[(_vm.tourOngoing)?_c('p',{staticClass:"finished-header"},[_vm._v("Finished events")]):_vm._e(),_vm._l((_vm.finishedEvents),function(event){return _c('div',{key:event.id,staticClass:"event-card"},[_c('h4',[_vm._v(_vm._s(event.name))]),(event.city)?_c('p',{staticClass:"location"},[_vm._v(" "+_vm._s(event.city.name)+", "+_vm._s(event.city.country)+" ")]):_vm._e(),_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.eventStartDate(event.startDate)))]),_c('div',{staticClass:"tags"},[(event.pdgaType)?_c('TjingToolTip',{attrs:{"text":"PDGA Tier"}},[_c('span',{staticClass:"card-label ocean"},[_vm._v(_vm._s(event.pdgaType.name))])]):_vm._e(),(_vm.distanceInKm(_vm.usersPosition, event.city))?_c('TjingToolTip',{attrs:{"text":"From your location"}},[_c('span',{staticClass:"card-label sunrise"},[_vm._v(_vm._s(_vm.distanceInKm(_vm.usersPosition, event.city))+" km")])]):_vm._e(),_c('span',{staticClass:"status-label strom",class:{
            right: _vm.distanceInKm(_vm.usersPosition, event.city) || event.pdgaType,
          }},[_vm._v(" Finished ")])],1),_c('router-link',{attrs:{"to":{
          name: 'public-event-results',
          params: { eventId: event.id },
        }}},[_c('button',{staticClass:"view-event"},[_vm._v("View event")])])],1)})],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }