export default {
  computed: {
    onTjingMobile() {
      return this.$store.getters.onTjingMobile;
    },
  },
  methods: {
    watchPosition() {},
    async getLocation() {
      if (this.onTjingMobile) {
        return new Promise((resolve, reject) => {
          try {
            const userLocation = JSON.parse(localStorage.getItem("userLocation"));
            if (userLocation) {
              resolve(userLocation);
            } else {
              throw { code: 1 };
            }
          } catch (error) {
            reject(error);
          }
        });
      } else {
        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };
        return new Promise((resolve, reject) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                resolve({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  altitude: position.coords.altitude,
                  altitudeAccuracy: position.coords.altitudeAccuracy,
                  accuracy: position.coords.accuracy,
                });
              },
              (err) => {
                reject(err);
              },
              options
            );
          } else {
            reject({ code: 1 });
          }
        });
      }
    },
    getDistanceInKM(lat1, lon1, lat2, lon2) {
      function deg2rad(deg) {
        return deg * (Math.PI / 180);
      }

      var R = 6371; // Radius of the earth in km
      var dLat = deg2rad(lat2 - lat1); // deg2rad below
      var dLon = deg2rad(lon2 - lon1);
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c; // Distance in km
      return d;
    },
  },
};
